<template>
    <div class="row g-5 g-xl-8">
        <h3 class="text-dark">{{ $t("menu.systemSetting") }}</h3>
        <el-divider class="mt-3 mb-6"></el-divider>
        <div class="col-xl-3" v-for="(setting, settingKey, settingIndex) in settings" :key="settingIndex">
            <div class="card module-card">
                <!--begin::Header-->
                <div class="card-header justify-content-center card-header">
                    <h3 class="card-title text-dark">{{ $t('pages.system.setting.types.' + settingKey) }}</h3>
                </div>
                <div class="card-body text-center">
                    <router-link :to="getRedirectUrl(setting, settingKey)">
                        <span class="svg-icon svg-icon-4x svg-icon-dark d-block my-2">
                            <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            redirectUrlPattern: '/system/setting/%s',
            customRedirect: {
                register: "/system/setting/register"
            }
        }
    },
    computed: {
        settings() {
            return this.$store.state.system.setting.table.data;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.systemSetting"), [this.$t("menu.systemManagement")]);
        this.$store.dispatch("system/setting/get");
    },
    methods: {
        getRedirectUrl(setting, settingKey) {
            if(this.customRedirect[settingKey]){
                return this.customRedirect[settingKey];
            }

            return this.sprintf(this.redirectUrlPattern, [settingKey.toLowerCase().replace('_', '-')]);
        }
    }
}
</script>

<style>
    .module-card .card-header{
        min-height: 65px;
    }
</style>